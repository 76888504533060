import axios from 'axios'
import store from '@/store'
console.log(process.env.NODE_ENV)
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: process.env.NODE_ENV === 'production' ? 'http://testOnline.netopstec.com/api' : '/api',
  baseURL: '/api',

  timeout: 6000 // 请求超时时间
})

request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  let token = store.state.userInfo?store.state.userInfo.token:''
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
request.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // console.log(response)
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});
export default request
