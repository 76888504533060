import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Picker,Tab, Tabs,Collapse, CollapseItem } from 'vant';
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Picker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.config.errorHandler = function (err, vm, info) {
  console.error(err)
  // console.log(err)
  // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
  // 只在 2.2.0+ 可用
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
