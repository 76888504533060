<template>
  <div id="app">
    <router-view />
    <!-- <bottom></bottom> -->
  </div>
</template>

<style lang="less">
#app {
  height: 100%;
}
</style>
<script>
import bottom from "./components/bottom.vue";
export default {
  components: {
    bottom,
  },
  created() {
    window.onerror = function (message, source, lineno, colno, error) {
      console.log(1);
    };
  },
};
</script>
