import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    address:{}
  },
  mutations: {
    SET_USERINFO(state, data) {
      state.userInfo = data
    },
    SET_ADDRESS(state, data) {
      state.address = data
    }
  },
  actions: {
  },
  modules: {
  },

  plugins: [persistedState()]
})
