<template>
  <div class="home">
    <header>
      <h5>网营快递管家</h5>
    </header>
    <div class="bodyer">
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="80%"
        :before-close="handleClose"
      >
        <p>您的运单号:{{ orderNo }}</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleSave">确认已下单</el-button>
        </span>
      </el-dialog>

      <div class="button" @click="DDsaoMa">
        <img src=".././assets/saoyisao.png" alt="" />
        <div>
          <p>扫一扫</p>
          <span>支持顺丰,中通</span>
        </div>
      </div>
      <span class="text"
        >请确认下单成功后,再点击扫码。避免意外记账,<br />如已发生,请联系前台。</span
      >
      <!-- <div class="button" @click="DDsaoMa()">
        <img src=".././assets/shunfeng.png" alt="" />
        <div>
          <p>扫码寄</p>
          <span>仅支持顺丰</span>
        </div>
      </div>
      <div class="button" @click="handleSend">
        <img src=".././assets/zhongtong.png" alt="" />
        <div>
          <p>填写寄</p>
          <span>仅支持中通</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { get, post } from "@/utils/http";
export default {
  components: { QrcodeStream },

  data() {
    return {
      dialogVisible: false, //对话框
      qrcode: false, // 是否开启扫码
      orderNo: "", //扫码结果订单号
      error: "", //错误信息
      orderType: "", //快递单号类型
    };
  },
  mounted() {
    this.getInfoCode(); //钉钉免登录获取用户code
    this.getUserName();
  },
  methods: {
    getInfoCode() {
      let that = this;
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src =
        "https://g.alicdn.com/dingding/dingtalk-jsapi/2.10.3/dingtalk.open.js";
      document.body.appendChild(s);
      setTimeout(() => {
        window.dd.ready(function () {
          // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
          window.dd.runtime.permission.requestAuthCode({
            corpId: "dinga7e92f372dba0344",
            onSuccess: function (info) {
              // alert(info.code)
              that.code = info.code;
              that.getUserName();
            },
            onFaiil(res) {
              alert("无法授权登录，请使用钉钉登录");
            },
          });
        });
      }, 500);
    },
    handleClose(done) {
      done();
    },
    DDsaoMa() {
      let that = this;
      dd.ready(function () {
        dd.biz.util.scan({
          type: "all", // type 为 all、qrCode、barCode，默认是all。
          onSuccess: function (data) {
            let sf = /(ucmp.sf-express.com){1}/;
            let zt = /(q.zto.com){1}/;
            if (sf.test(data.text) && !zt.test(data.text)) {
              that.orderType = "SF";
              that.orderNo = data.text.split("?p1=")[1];
            } else if (!sf.test(data.text) && zt.test(data.text)) {
              that.orderType = "ZTO";
              that.orderNo = data.text.split("?billCode=")[1];
            } else alert("暂不支持的快递类型");

            if (that.orderType && that.orderNo) {
              that.dialogVisible = true;
            }
            // get(`/express/select/${that.orderNo}`).then(res => {
            //   if (res.data) {
            //     if (res.data.expressActive == 0) {
            //       that.$router.push({
            //         path: "/record",
            //         query: {
            //           tabIndex: 0,
            //           billCode: that.orderNo,
            //         },
            //       });
            //     } else {
            //       that.$router.push({
            //         path: "/record",
            //         query: {
            //           tabIndex: 1,
            //           billCode: that.orderNo,
            //         },
            //       });
            //     }
            //   } else {
            //     that.$router.push({
            //       path: "/about",
            //       query: {
            //         orderType: that.orderType,
            //         orderNo: that.orderNo,
            //       },
            //     });
            //   }
            // });
            // }
          },
          onFail: function (err) {},
        });
      });
    },
    handleSave() {
      this.dialogVisible = false;
      post("/express/save", {
        mobile: this.$store.state.userInfo.mobile,
        userName: this.$store.state.userInfo.nickName,
        billCode: this.orderNo,
        category: this.orderType,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("信息已保存");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getUserName() {
      get("/authExpress", {
        code: this.code,
      }).then((res) => {
        this.$store.commit("SET_USERINFO", res.data);
      });
    },

    handleSend() {
      this.$router.push({
        path: "/about",
        query: {
          orderType: "zt",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
}
header {
  height: 80px;
  padding-top: 20px;
  background-color: #489ef6;
  position: relative;
  h5 {
    color: white;
    font-size: 18px;
    font-weight: normal;
    text-align: center;
  }
}
.bodyer {
  flex: 1;
  height: 100%;
  .button {
    margin: 50px auto 0;
    background-color: #ffffff;
    width: 250px;
    height: 50px;
    box-shadow: 0px 0px 5px #888888;
    display: flex;
    justify-content: space-between;
    img {
      width: 50px;
      height: 50px;
    }
    div {
      width: 200px;
      p {
        font-weight: 900;
        font-size: 20px;
        text-align: center;
        line-height: 30px;
        color: #000000;
      }
      span {
        font-size: 14px;
        display: block;
        text-align: center;
        color: #887f7f;
      }
    }
  }
}
.qrcode {
  position: fixed !important;
  top: 0;
  z-index: 10000 !important;
  height: 100%;
}
.text {
  color: #666666;
  display: block;
  margin-top: 20px;
  text-align: center;
  // margin: 0 auto;
}
</style>