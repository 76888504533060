<template>
  <div class="bottom">
    <li :class="index == 1 ? 'color' : ''" @click="handleIndex(1)">
      <i class="el-icon-s-home"></i>
      <p>首页</p>
    </li>
    <li :class="index == 2 ? 'color' : ''" @click="handleIndex(2)">
      <i class="el-icon-s-promotion"></i>
      <p>寄件记录</p>
    </li>
    <li :class="index == 3 ? 'color' : ''" @click="handleIndex(3)">
      <i class="el-icon-s-custom"></i>
      <p>个人中心</p>
    </li>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 1,
    };
  },
  methods: {
    handleIndex(index) {
      this.index = index;
      switch (index) {
        case 1:
          this.$router.push("/");
          break;
        case 2:
          this.$router.push("/record");
          break;
        case 3:
          this.$router.push("/my");
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bottom {
  border-top: 1px solid #93c6fa;
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  height: 50px;
  .color {
    color: #6685ec;
  }
  li {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }
}
</style>